import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import person from "../images/svg/person-worried.svg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="center-wrapper">
      <h1>Oops!</h1>
      <img src={person} alt="Person worried" style={{ width: "180px", margin: "0 auto" }} />
      <p>Sorry, looks like this page doesn&#39;t exist anymore.</p>
      <p><Link to="/" className="CTA">Go home</Link></p>

    </div>

  </Layout>
)

export default NotFoundPage
